<template>
  <v-app>
    <loader />
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {},
  computed: {
    isAxiosRequest() {
      return this.$store.getters['requestWatcher/isAxiosRequest'];
    },
  },
  mounted() {
    this.$store.dispatch('injectIntegrationsScripts');

    // TODO временный фикс пока не решена проблема с долгой отправкой писем при регистрации
    // this.$http.defaults.timeout = (process.env.NODE_ENV === 'production') ? 5000 : 0;
    this.$http.defaults.withCredentials = true;

    // Перехватывает все запросы получаемые через axios
    this.$http.interceptors.response.use((response) => {
      if (response.status === 200 && response.data.result) {
        let convertNumericKeysObjectsToArrays = function f(obj) {
          Object.keys(obj).forEach((key) => {
            // 1) Если нужно передать пустой массив из ЮМИ - отдаем строку "empty_array"
            if (obj[key] === 'empty_array') obj[key] = [];

            // 2) Преобразовывает объекты с численными ключами (есть ключ "0") в массивы
            if (typeof obj[key] === 'object' && obj[key] !== null) {
              if (obj[key].hasOwnProperty(0)) {
                obj[key] = Object.values(obj[key]);
              }
              f(obj[key]);
            }
          });
        };
        convertNumericKeysObjectsToArrays(response.data);
      }
      // 3) Выводим стандартные сообщения (toasts)
      this.$helpers.showStandardResponseMessages(response.data, this);

      this.$store.dispatch('requestWatcher/decrementCountRequest');
      return response;
    });

    this.$store.dispatch('auth/getCurrentUser');
    this.$store.dispatch('getGlobalLists');

    // Основная точка получения данных из плагина
    window.addEventListener('pluginSourceEvent', (event) => {
      let data = event.detail;
      switch (data.action) {
        case 'notification':
          this.$helpers.showStandardResponseMessages(data, this);
          break;
        case 'pluginStatus':
          this.$store.commit('fgisPlugin/SET_PLUGIN_STATUS', data);
          break;
        default:
          break;
      }
    });

    this.$store.dispatch('fgisPlugin/getPluginUpdateManifest');
    this.$store.dispatch('fgisPlugin/setBrowserName');
    // Отправляем запрос к плагину - получение статуса плагина и авторизации во ФГИС
    this.$store.dispatch('fgisPlugin/getPluginStatus');

    this.$http.interceptors.request.use((config) => {
      this.$store.dispatch('requestWatcher/incrementCountRequest');
      return config;
    });
  },
};
</script>
