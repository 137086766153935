// Global (default Vuetify) validation rules
const validationRules = {
  phone: [
    (v) => !!v || 'Необходимо указать телефон',
    (v) => (v && v.length == 10) || 'Некорректный формат телефона',
  ],
  phoneOptional: [
    (v) => v.length == 0 || v.length == 10 || 'Некорректный формат телефона',
  ],
  email: [
    (v) => !!v || 'Необходимо указать e-mail',
    (v) => /.+@.+\..+/.test(v) || 'Введен некорректный e-mail',
  ],
  required: [
    (v) => {
      if (v instanceof Array) {
        return v.length > 0 || 'Поле обязательно для заполнения';
      }
      return !!v || 'Поле обязательно для заполнения';
    },
  ],
  number: [(v) => /^\d+$/.test(v) || 'Необходимо ввести число'],
  bik: [
    (v) => !!v || 'Поле обязательно для заполнения',
    (v) => (v && v.length == 9) || 'Некорректный формат бик',
  ],
  bankAccount: [
    (v) => !!v || 'Поле обязательно для заполнения',
    (v) => (v && v.length == 20) || 'Некорректный формат счета',
  ],
};

const errorMessages = {
  show(field) {
    const errors = [];
    if (!field.$dirty) return errors;
    field.$required === false && errors.push('Поле обязательно для заполнения');
    field.$phone === false && errors.push('Некорректный формат телефона');
    field.$email === false && errors.push('Введен некорректный e-mail');
    return errors;
  },
};

// Vuelidate validators
import { required } from 'vuelidate/lib/validators';

// Custom validators
const validators = {
  phone: (v) => Boolean(v) && v.length === 10,
  phoneOptional: (v) => !v || v.length === 10,
};

// Specific forms Vuelidate options/rules
const vuelidateRules = {
  labValidations(_this) {
    if (
      _this.current_data.fields.lab_type ==
      _this.$globals.manufacturing_lab_type_id
    ) {
      return {
        current_data: {
          name: {
            $required: required,
          },
          fields: {
            title_full: {
              $required: required,
            },
            lab_type: {
              $required: required,
            },
            reg_number: {},
            country: {
              $required: required,
            },
            name_director: {},
            legal_address: {
              $required: required,
            },
            attested_from_date: {},
            attested_to_date: {},
            voluntary: {},
            attested_unlimited: {},
            attested_unlimited_text: {},
            customs_declaration: {},
            phone: {},
          },
        },
      };
    } else {
      return {
        current_data: {
          name: {
            $required: required,
          },
          fields: {
            title_full: {
              $required: required,
            },
            lab_type: {
              $required: required,
            },
            reg_number: {
              $required: required,
            },
            country: {
              $required: required,
            },
            name_director: {
              $required: required,
            },
            legal_address: {
              $required: required,
            },
            attested_from_date: {
              $required: required,
            },
            attested_to_date: _this.current_data.fields.attested_unlimited
              ? { $required: required }
              : {},
            voluntary: {},
            attested_unlimited: {},
            attested_unlimited_text: {},
            customs_declaration: {},
            phone: {},
          },
        },
      };
    }
  },
  declarationProductValidations() {
    return {
      product: {
        fields: {
          product_name: {
            $required: required,
          },
        },
      },
    };
  },
  clientApplicationValidations() {
    let declarationProductValidations = {
      fields: {
        product_name: {
          $required: required,
        },
      },
    };

    return {
      new_declaration_product: declarationProductValidations,
      application: {
        fields: {
          declaration_products: {
            $required: required,
            $each: declarationProductValidations,
          },
        },
      },
    };
  },
  legalPersonValidations(isForeign) {
    return {
      fields: isForeign
        ? {
            legal_subject_type: { $required: required },
            country: { $required: required },
            full_name: { $required: required },
            name: { $required: required },
            legal_address: { $required: required },
            defacto_address: { $required: required },
          }
        : {
            legal_subject_type: { $required: required },
            country: { $required: required },
            full_name: { $required: required },
            name: { $required: required },
            legal_address: { $required: required },
            defacto_address: { $required: required },
            ogrn: { $required: required },
            inn: { $required: required },
            legal_form: { $required: required },
            region: { $required: required },
            director_lname: { $required: required },
            director_fname: { $required: required },
            director_post: { $required: required },
            director_lname_genitive: { $required: required },
            director_fname_genitive: { $required: required },
            director_post_genitive: { $required: required },
            director_lname_dative: { $required: required },
            director_fname_dative: { $required: required },
            director_post_dative: { $required: required },
            phone_number: { $required: required },
            email: { $required: required },
          },
    };
  },
  selfemployedValidations() {
    return {
      fields: {
        fname: { $required: required },
        lname: { $required: required },
        father_name: { $required: required },
        phone_number: { $required: required },
        legal_address: { $required: required },
        inn: { $required: required },
      },
    };
  },
};

export { validationRules, vuelidateRules, errorMessages, validators };
