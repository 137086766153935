import Vue from 'vue';
import helpers from './plugins/helpers.js';

Vue.filter('formatDateTime', function (value) {
  return helpers.formatDateTime(value);
});

Vue.filter('formatDate', function (value, options) {
  return helpers.formatDate(value, options);
});

Vue.filter('cutoff', function (value, length = 60) {
  return value.length <= length
    ? value
    : value.substring(0, length).trim() + '...';
});
