import state from './moduleDeclarationModerationState.js';
import mutations from './moduleDeclarationModerationMutations.js';
import actions from './moduleDeclarationModerationActions.js';
import getters from './moduleDeclarationModerationGetters.js';

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
