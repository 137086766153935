export default {
  SET_USER(state, val) {
    if (val) {
      if (!val.last_application_id) val.last_application_id = '';
      this.state.user = val;
    } else {
      this.state.user = {
        id: 0,
        name: 'Гость',
        user_role: 'guest',
        user_info: {},
        last_application_id: '',
      };
    }
  },
};
