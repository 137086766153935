export default {
  async getPluginUpdateManifest({ commit }) {
    let response;
    try {
      response = await this._vm.$http({
        method: 'GET',
        url: '/udata/data/get_plugin_update_manifest/.json',
      });
      let result = response.data?.result;
      if (result) {
        commit('SET_PLUGIN_LATEST_VERSION', result.version);
        commit('SET_PLUGIN_DOWNLOAD_URL', result.update_link);
      }
    } catch (e) {
      console.error('Error accessing plugin update manifest', e);
    }
  },
  async setBrowserName({ commit }) {
    let browserName;
    /Chrome/.test(navigator.userAgent) && (browserName = 'Chrome');
    /Firefox/.test(navigator.userAgent) && (browserName = 'Firefox');
    commit('SET_BROWSER_NAME', browserName);
  },
  async getPluginStatus() {
    window.dispatchEvent(
      new CustomEvent('pluginTargetEvent', {
        detail: { action: 'getPluginStatus' },
      })
    );
  },
  callPlugin({ state }, detail) {
    if (!state.isInstalled) {
      this._vm.$toasted.show('Плагин не установлен', { type: 'warning' });
      return false;
    }

    window.dispatchEvent(
      new CustomEvent('pluginTargetEvent', { detail: detail })
    );
    return true;
  },
};
