<template>
  <v-overlay :value="show">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'Loader',
  computed: {
    show() {
      return this.$store.getters['loader'];
    },
  },
};
</script>
