/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
==========================================================================================*/

const mutations = {
  SET_DEBOUNCE_WAIT_TIME(state, val) {
    state.debounceWaitTime = parseInt(val);
  },
  SET_LOADER(state, val) {
    state.loader = val;
  },
  SET_JIVO_INVITATION(state, val) {
    state.jivoInvitation = val;
  },
  SET_PAYMENTS_LIST(state, val) {
    state.paymentsList = val;
  },
};

export default mutations;
