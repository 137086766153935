import Vue from 'vue';
import Inputmask from 'inputmask';

const default_inputmask_settings = {
  showMaskOnHover: false,
  autoUnmask: true,
};

Vue.directive('mask', {
  bind: function (el, binding) {
    let settings = binding.value;
    if (typeof settings === 'string') {
      settings = {
        mask: settings,
      };
    }

    let input = el.querySelector('input');
    if (input) {
      Inputmask({ ...default_inputmask_settings, ...settings }).mask(input);
      input.addEventListener('paste', () => {
        input.dispatchEvent(new Event('input'));
      });
    } else {
      Inputmask({ ...default_inputmask_settings, ...settings }).mask(el);
    }
  },
});
