import state from './moduleRequestWatcherState.js';
import mutations from './moduleRequestWatcherMutations.js';
import actions from './moduleRequestWatcherActions.js';
import getters from './moduleRequestWatcherGetters.js';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
