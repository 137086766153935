export default {
  toggleModeration({ commit }) {
    commit('TOGGLE_MODERATION');
  },
  toggleOffModeration({ commit }) {
    commit('SET_MODERATION', false);
  },
  commentCountUpdate({ commit }, count) {
    commit('SET_COMMENT_COUNT', count);
  },
};
