<template>
  <v-btn v-bind="$attrs" :disabled="disabledBtn" tile v-on="$listeners">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'RBtn',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disabledBtn() {
      return (
        this.disabled || this.$store.getters['requestWatcher/isAxiosRequest']
      );
    },
  },
};
</script>
