import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
  duration: '5000',
  keepOnHover: true,
  closeOnSwipe: false,
  position: 'bottom-right',
  className: 'max-width-400',
  containerClass: 'v-application',
  iconPack: 'custom-class',
});

Vue.toasted.register('formValidationError', 'Заполните все обязательные поля', {
  type: 'error',
});
