import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import moduleAuth from './auth/moduleAuth.js';
import moduleFgisPlugin from './fgisPlugin/moduleFgisPlugin.js';
import moduleRequestWatcher from './requestWatcher/moduleRequestWatcher.js';
import moduleDeclarationModeration from './declarationModeration/moduleDeclarationModeration.js';

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    fgisPlugin: moduleFgisPlugin,
    requestWatcher: moduleRequestWatcher,
    declarationModeration: moduleDeclarationModeration,
  },
  strict: process.env.NODE_ENV !== 'production',
});
