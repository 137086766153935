import qs from 'qs';

const actions = {
  // ПОЛУЧЕНИЕ ОБЪЕКТОВ СПРАВОЧНИКА
  async getDictionaryItems(_, $type_id) {
    // TODO отрефакторить - много где можно было бы использовать, но с фильтрами
    let items = [];

    let response;
    try {
      response = await this._vm.$http({
        method: 'POST',
        url: `/udata/data/get_objects/list/.json`,
        data: qs.stringify({
          type_id: $type_id,
        }),
      });
      let data = response.data;
      this._vm.$helpers.showStandardResponseMessages(data, this);
      if (data.status === 'success') {
        items = data.result.items ? data.result.items : [];
      }
    } catch (err) {
      console.error('getDictionaryItems error:', err);
      this._vm.$toasted.error(err);
    }

    return items;
  },

  // 3rd PARTY SERVICES
  injectIntegrationsScripts() {
    let jivoToken =
      process.env.NODE_ENV === 'production' ? 'CSvCfDAyHs' : 'XHFPdUs4u8';
    /*eslint-disable */
    let jivoCallBackOnOpen = `function jivo_onOpen() {
      let user = JSON.parse(localStorage.getItem('userData'));
      let contactInfo = {
        description: 'Cтраница запроса: ' + window.location.href,
      };
      if (user) {
        contactInfo.name = user.user_name;
        contactInfo.email = user.email;
        contactInfo.phone = '+7' + user.phone;
        contactInfo.description += 
          '; Менеджер: ' + user.manager.fields.lname + ' ' + user.manager.fields.fname + 
          ' (' + user.manager.fields["e-mail"] + ')';
      }
      jivo_api.setContactInfo(contactInfo);
    }`;
    let scripts = [
      // ROISTAT
      [
        'text',
        `(function(w, d, s, h, id) {
          w.roistatProjectId = id; w.roistatHost = h;
          var p = d.location.protocol == "https:" ? "https://" : "http://";
          var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
          var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
        })(window, document, 'script', 'cloud.roistat.com', 'e05edb4fac973e66dff10877f8644697');`,
      ],
      [
        'text',
        `window.onRoistatAllModulesLoaded = function () {
          window.roistat.leadHunter.isEnabled = false;
        };`,
      ],
      // JIVOSITE
      ['src', `//code-ya.jivosite.com/widget/${jivoToken}`],
      ['text', jivoCallBackOnOpen],
    ];
    /*eslint-enable */

    scripts.forEach((script) => {
      let [type, code] = script;
      let tag = document.createElement('script');
      tag.async = false;
      switch (type) {
        case 'text':
          tag.text = code;
          break;
        case 'src':
          tag.setAttribute('src', code);
          break;
        default:
          break;
      }
      document.body.appendChild(tag);
    });
  },

  // ROISTAT
  getRoistatVisit() {
    let getCookie = (window.getCookie = function (name) {
      let matches = document.cookie.match(
        /* eslint-disable */
        new RegExp(
          '(?:^|; )' +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
            '=([^;]*)'
        )
        /* eslint-enable */
      );
      return matches ? decodeURIComponent(matches[1]) : 'nocookie';
    });
    let roistaVisit = getCookie('roistat_visit');
    if (roistaVisit === 'nocookie') {
      window.onRoistatAllModulesLoaded = function () {
        window.roistat.registerOnVisitProcessedCallback(function () {
          roistaVisit = window.roistat.getVisit();
        });
      };
    }
    return roistaVisit;
  },

  // JIVO CHAT
  async openJivoChat({ state, dispatch }, problem) {
    // eslint-disable-next-line no-undef
    let chat = jivo_api ? jivo_api : null;
    if (chat) {
      await dispatch('setJivoCustomData', problem);
      if (state.jivoInvitation) {
        chat.open();
      } else {
        await dispatch('openJivoInvitation', problem);
      }
    }
  },
  async setJivoCustomData(_, problem) {
    // eslint-disable-next-line no-undef
    let chat = jivo_api ? jivo_api : null;
    if (chat) {
      let legalPerson = '';
      let response;
      try {
        response = await this._vm.$http({
          method: 'POST',
          url: '/udata/data/get_legal_persons/.json',
          data: qs.stringify({
            type_id: 48,
          }),
        });
        let data = response.data;
        if (data.status === 'success' && data.result?.items?.length > 0) {
          legalPerson = data.result.items[0].name;
        }
      } catch (err) {
        console.error('getLegalPersons error:', err);
        this._vm.$toasted.error(err);
      }
      let activePath = window.location.href;
      chat.setCustomData([
        {
          title: 'Юридическое лицо',
          content: legalPerson,
        },
        {
          title: 'Проблема',
          content: problem + ' ' + activePath,
          link: activePath,
        },
      ]);
    }
  },
  async openJivoInvitation({ commit }, problem) {
    // eslint-disable-next-line no-undef
    let chat = jivo_api ? jivo_api : null;
    if (chat) {
      let applicationId = window.location.pathname.match(/\/\d*$/)[0].slice(1);
      let greeting = `Здравствуйте! Заявка ${applicationId}. 
      Проблема: ${problem}. 
      Всё верно?`;
      chat.showProactiveInvitation(greeting);
      commit('SET_JIVO_INVITATION', true);
    }
  },

  // LOADER
  showLoader({ commit }) {
    commit('SET_LOADER', true);
  },
  hideLoader({ commit }) {
    commit('SET_LOADER', false);
  },

  // ПОЛУЧЕНИЕ ГЛОБАЛЬНЫХ СПИСКОВ
  getGlobalLists({ dispatch }) {
    dispatch('getPaymentsList');
  },
  async getPaymentsList({ commit }) {
    let response;
    try {
      response = await this._vm.$http({
        method: 'POST',
        url: '/udata/emarket/get_payments_list/.json',
      });
      let data = response.data;
      if (data.status === 'success') {
        commit('SET_PAYMENTS_LIST', data.result);
      }
    } catch (err) {
      console.error('udata/emarket/get_payments_list error:', err);
      this.$toasted.error(err);
    }
  },
};

export default actions;
