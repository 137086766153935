import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './registerServiceWorker';

// Vuetify
import vuetify from './plugins/vuetify';

// Toasts
import './plugins/toasted';

// Axios
import axios from 'axios';
Vue.prototype.$http = axios;

// ACL
import acl from './plugins/acl';

// Global components
import './globalComponents.js';

// Helpers
import helpers from './plugins/helpers.js';
Vue.prototype.$helpers = helpers;

// Global constants
import { globals } from './globalConstants.js';
Vue.prototype.$globals = globals;

// Кастомные директивы
import './customDirectives.js';

// Фильтры
import './filters.js';

// Кастомные глобальные стили
import './sass/custom.sass';

// Form validation
import { validationRules, errorMessages } from './plugins/validationRules.js';
Vue.prototype.$validationRules = validationRules;
Vue.prototype.$errorMessages = errorMessages;

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  acl,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
