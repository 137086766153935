const state = {
  user: {
    is_auth: false,
    user_role: 'guest',
    user_id: 0,
    user_name: 'Гость',
    user_login: 'guest',
    last_application_id: null,
    root_route: '/',
    email: '',
    phone: '',
    manager: false,
  },
  loader: false,
  jivoInvitation: false, // Приглашение Jivo уже было открыто

  // ГЛОБАЛЬНЫЕ СПИСКИ
  paymentsList: [], // Список способов оплаты

  debounceWaitTime: 1000,
};

export default state;
