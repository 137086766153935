export default {
  incrementCountRequest(state) {
    state.count_axios_request++;
  },
  decrementCountRequest(state) {
    if (state.count_axios_request > 0) {
      state.count_axios_request--;
    }
  },
};
