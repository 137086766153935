export default {
  TOGGLE_MODERATION(state) {
    state.moderation = !state.moderation;
  },
  SET_MODERATION(state, value) {
    state.moderation = value;
  },
  SET_SHOW_COMMENT_FORM(state, value) {
    state.showCommentForm = value;
  },
  SET_SELECTION_DATA(state, data) {
    state.selectionData = data;
  },
  SET_COMMENT_COUNT(state, count) {
    state.commentCount = count;
  },
  SET_ALL_COMMENTS_RESOLVED(state, value) {
    state.allCommentsResolved = value;
  },
};
