const state = {
  browserName: undefined,
  isInstalled: false,
  installedVersion: null,
  latestVersion: null,
  authFgis: false,
  authFgisLegalPersonInn: null,
  downloadURL: null,
};

export default state;
