export default {
  SET_PLUGIN_STATUS(state, data) {
    state.isInstalled = data.installed;
    state.installedVersion = data.version;
    state.authFgis = data.authFgis;
    state.authFgisLegalPersonInn = data.authFgisLegalPersonInn;
  },
  SET_PLUGIN_LATEST_VERSION(state, version) {
    state.latestVersion = version;
  },
  SET_PLUGIN_DOWNLOAD_URL(state, url) {
    state.downloadURL = url;
  },
  SET_BROWSER_NAME(state, browserName) {
    state.browserName = browserName;
  },
};
