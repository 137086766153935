import Vue from 'vue';
import { AclInstaller, AclCreate, AclRule } from 'vue-acl';
import router from '@/router';

Vue.use(AclInstaller);

export default new AclCreate({
  initial: 'guest',
  notfound: { path: '/error-403' },
  router,
  acceptLocalRules: true,
  globalRules: {
    // Menu rules
    menuDashboard: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('mockuper')
      .or('purchaser')
      .or('manager')
      .generate(),
    menuStaff: new AclRule('admin').or('director').generate(),
    menuGuidesList: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('manager')
      .generate(),
    menuFgisEmployeeGuide: new AclRule('admin')
      .or('director')
      .or('expert')
      .generate(),
    menuClients: new AclRule('admin').or('director').or('manager').generate(),
    menuLegalPersons: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('manager')
      .generate(),
    menuApplications: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('manager')
      .or('purchaser')
      .generate(),
    menuJobs: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('mockuper')
      .or('manager')
      .generate(),
    menuRegistry: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('manager')
      .generate(),

    // Resources access level rules
    applicationEdit: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('manager')
      .or('certification_center')
      .generate(),
    applicationView: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('manager')
      .or('mockuper')
      .or('certification_center')
      .generate(),
    declarationEdit: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('mockuper')
      .or('manager')
      .or('certification_center')
      .generate(),
    declarationView: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('mockuper')
      .or('manager')
      .or('certification_center')
      .generate(),
    exemptionLetterEdit: new AclRule('admin')
      .or('director')
      .or('expert')
      .generate(),
    exemptionLetterView: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('manager')
      .generate(),
    ordersEdit: new AclRule('admin')
      .or('director')
      .or('expert') // TODO эксперт с заказами не работает вроде
      .or('manager')
      .generate(),
    ordersView: new AclRule('admin')
      .or('director')
      .or('expert') // TODO эксперт с заказами не работает вроде
      .or('manager')
      .or('certification_center')
      .or('client')
      .generate(),

    // Group rules
    guest: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('manager')
      .or('purchaser')
      .or('certification_center')
      .or('client')
      .or('guest')
      .generate(),
    notguest: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('manager')
      .or('purchaser')
      .or('certification_center')
      .or('client')
      .generate(),
    client: new AclRule('client').generate(),
    certificationCenter: new AclRule('certification_center').generate(),
    purchaser: new AclRule('purchaser').or('admin').generate(),
    manager: new AclRule('manager').or('director').or('admin').generate(),
    expert: new AclRule('expert').or('admin').generate(),
    mockuper: new AclRule('mockuper').or('admin').generate(),
    director: new AclRule('director').or('admin').generate(),
    admin: new AclRule('admin').generate(),
    notEmployee: new AclRule('certification_center').or('client').generate(),
    employee: new AclRule('admin')
      .or('director')
      .or('expert')
      .or('mockuper')
      .or('manager')
      .or('purchaser')
      .generate(),
  },
});
