import axios from 'axios';
import qs from 'qs';

class AuthService {
  async signIn(user) {
    let response;
    try {
      response = await axios({
        method: 'POST',
        url: '/udata/users/sign_in/.json',
        data: qs.stringify(user),
        withCredentials: true,
      });
      return response.data;
    } catch (err) {
      console.log('auth/signIn error:', err);
      return false;
    }
  }

  async signOut() {
    await axios.get('/udata/users/logout/0/.json', {
      withCredentials: true,
    });
  }

  async getCurrentUser() {
    let response;
    try {
      response = await axios.get('/udata/users/get_current_user/.json', {
        withCredentials: true,
      });
      return response.data.result;
    } catch (err) {
      console.error('auth/getCurrentUser error:', err);
      return false;
    }
  }
}

export default new AuthService();
