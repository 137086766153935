import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from '@/plugins/auth';
import store from './store';

Vue.use(VueRouter);

const routes = [
  // =============================================================================
  // MAIN LAYOUT ROUTES
  // =============================================================================
  {
    path: '',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: 'root',
      },
      // =============================================================================
      // ИНТЕРФЕЙС КЛИЕНТА
      // =============================================================================

      // =============================================================================
      // Профиль пользователя (форма учетной записи)
      // =============================================================================
      {
        path: '/profile',
        name: 'client-profile',
        meta: { rule: 'notEmployee' },
        component: () => import('@/views/client/ClientProfile.vue'),
      },
      // =============================================================================
      // Юридические лица (список и форма редактирования)
      // =============================================================================
      {
        path: '/legal-persons',
        name: 'client-legal-persons',
        meta: { rule: 'notEmployee' },
        component: () => import('@/views/client/ClientLegalPersons.vue'),
      },
      {
        path: '/legal-person/:id?',
        name: 'client-legal-person',
        meta: { rule: 'notEmployee' },
        component: () => import('@/views/client/ClientLegalPerson.vue'),
      },
      // =============================================================================
      // Заявки (список и форма редактирования)
      // =============================================================================
      {
        path: '/applications',
        name: 'client-applications',
        meta: { rule: 'notEmployee' },
        component: () => import('@/views/client/ClientApplications.vue'),
      },
      {
        path: '/application/:id?/:tab?/:job?',
        name: 'client-application',
        meta: { rule: 'notEmployee' },
        component: () => import('@/views/common/Application.vue'),
      },
      // =============================================================================
      // Работы (форма декларации)
      // =============================================================================
      {
        path: '/declaration/:id',
        name: 'client-declaration',
        meta: { rule: 'notEmployee' },
        props: true,
        component: () => import('@/views/manager/ManagerDeclaration.vue'),
      },

      // =============================================================================
      // ИНТЕРФЕЙС МЕНЕДЖЕРА
      // =============================================================================
      {
        path: '/manager',
        redirect: '/manager/applications',
        meta: { rule: 'employee' },
        component: () => import('@/views/PassThrough.vue'),
        children: [
          {
            path: 'clients',
            name: 'manager-clients',
            meta: { rule: 'menuClients' },
            props: true,
            component: () => import('@/views/manager/ManagerClients.vue'),
          },
          {
            path: 'client/:id?',
            name: 'manager-client',
            meta: { rule: 'menuClients' },
            component: () => import('@/views/manager/ManagerClient.vue'),
          },
          {
            path: 'legal-persons',
            name: 'manager-legal-persons',
            meta: { rule: 'menuLegalPersons' },
            props: true,
            component: () => import('@/views/manager/ManagerLegalPersons.vue'),
          },
          {
            path: 'legal-person/:id?',
            name: 'manager-legal-person',
            meta: { rule: 'menuLegalPersons' },
            props: true,
            component: () => import('@/views/manager/ManagerLegalPerson.vue'),
          },
          {
            path: 'applications',
            name: 'manager-applications',
            meta: { rule: 'menuApplications' },
            props: true,
            component: () => import('@/views/manager/ManagerApplications.vue'),
          },
          {
            path: 'application/:id?/:tab?/:job?',
            name: 'manager-application',
            meta: { rule: 'applicationView' },
            props: true,
            component: () => import('@/views/common/Application.vue'),
          },
          {
            path: 'jobs',
            name: 'manager-jobs',
            meta: { rule: 'menuJobs' },
            props: true,
            component: () => import('@/views/manager/ManagerJobs.vue'),
          },
          {
            path: 'declaration/:id?',
            name: 'manager-declaration',
            meta: { rule: 'declarationView' },
            props: true,
            component: () => import('@/views/manager/ManagerDeclaration.vue'),
          },
          {
            path: 'exemption-letter/:id?',
            name: 'manager-exemption-letter',
            meta: { rule: 'exemptionLetterView' },
            props: true,
            component: () =>
              import('@/views/manager/ManagerExemptionLetter.vue'),
          },
          {
            path: 'registry',
            name: 'registry',
            meta: { rule: 'menuRegistry' },
            props: true,
            component: () => import('@/views/manager/Registry.vue'),
          },
          {
            path: 'orders',
            name: 'orders',
            meta: { rule: 'director' },
            props: true,
            component: () => import('@/views/manager/OrdersPage.vue'),
          },

          // =============================================================================
          // Справочники
          // =============================================================================
          {
            path: 'guides/list',
            name: 'guides-list',
            meta: { rule: 'menuGuidesList' },
            component: () => import('@/views/guides/GuidesList.vue'),
          },
          {
            path: 'guides',
            name: 'guides',
            meta: { rule: 'menuGuidesList' },
            component: () => import('@/views/PassThrough.vue'),
            children: [
              {
                path: 'labs',
                name: 'labs',
                meta: { rule: 'menuGuidesList' },
                component: () => import('@/components/guides/GuideLabs.vue'),
              },
              {
                path: 'lab/:id?',
                name: 'lab',
                meta: { rule: 'menuGuidesList' },
                component: () => import('@/components/guides/GuideLab.vue'),
              },
              {
                path: 'fgis_employees',
                name: 'fgis_employees',
                meta: { rule: 'menuFgisEmployeeGuide' },
                component: () =>
                  import('@/components/guides/GuideFgisEmployees.vue'),
              },
              {
                path: 'fgis_employee/:id?',
                name: 'fgis_employee',
                meta: { rule: 'menuFgisEmployeeGuide' },
                component: () =>
                  import('@/components/guides/GuideFgisEmployee.vue'),
              },
              {
                path: 'certification-bodies',
                name: 'certification-bodies',
                meta: { rule: 'menuFgisEmployeeGuide' },
                component: () =>
                  import('@/components/guides/GuideCertificationBodies.vue'),
              },
              {
                path: 'certification-body/:id?',
                name: 'certification-body',
                meta: { rule: 'menuFgisEmployeeGuide' },
                component: () =>
                  import('@/components/guides/GuideCertificationBody.vue'),
              },
              {
                path: 'products-catalog',
                name: 'products-catalog',
                meta: { rule: 'menuGuidesList' },
                component: () =>
                  import('@/components/guides/GuideProductsCatalog.vue'),
              },
              {
                path: 'products-catalog-item/:id?',
                name: 'products-catalog-item',
                meta: { rule: 'menuGuidesList' },
                component: () =>
                  import('@/components/guides/GuideProductsCatalogItem.vue'),
              },
              {
                path: 'onetime-promocodes',
                name: 'onetime-promocodes',
                meta: { rule: 'admin' },
                component: () =>
                  import('@/components/guides/GuideOnetimePromocodes.vue'),
              },
              {
                path: 'onetime-promocode-item/:id?',
                name: 'onetime-promocode-item',
                meta: { rule: 'admin' },
                component: () =>
                  import('@/components/guides/GuideOnetimePromocodeItem.vue'),
              },
            ],
          },
          // =============================================================================
          // Dashboard
          // =============================================================================
          {
            path: '/dashboard',
            name: 'dashboard',
            meta: { rule: 'menuDashboard' },
            component: () => import('@/views/common/Dashboard.vue'),
          },
          // =============================================================================
          // Управление сотрудниками
          // =============================================================================
          {
            path: 'staff',
            name: 'admin-staff',
            meta: { rule: 'menuStaff' },
            component: () => import('@/views/admin/AdminStaff.vue'),
          },
          // =============================================================================
          // Страница задачи
          // =============================================================================
          {
            path: '/task/:id?',
            name: 'task',
            meta: { rule: 'employee' },
            component: () => import('@/views/common/Task.vue'),
          },
        ],
      },
      {
        path: '/contacts',
        name: 'contacts',
        meta: { rule: 'guest' },
        component: () => import('@/views/Contacts.vue'),
      },
      {
        path: '/agreement',
        name: 'agreement',
        meta: { rule: 'guest' },
        component: () => import('@/views/Agreement.vue'),
      },
    ],
  },
  // =============================================================================
  // Заказ в один клик
  // =============================================================================
  {
    path: '/order/:state?/:orderParam?',
    name: 'order',
    meta: { rule: 'guest' },
    props: true,
    component: () => import('@/views/OneClickOrder.vue'),
  },
  // =============================================================================
  // SIMPLE LAYOUT ROUTES
  // =============================================================================
  {
    path: '',
    component: () => import('@/layouts/Simple.vue'),
    children: [
      // =============================================================================
      // Обработчики системных редиректов (UMI)
      // =============================================================================
      {
        path: '/wfa/:value',
        name: 'webform-application',
        meta: { rule: 'guest' },
        props: true,
        component: () => import('@/views/WebformApplication.vue'),
      },
    ],
  },
  // =============================================================================
  // CENTERED LAYOUT ROUTES
  // =============================================================================
  {
    path: '',
    component: () => import('@/layouts/Centered.vue'),
    children: [
      // =============================================================================
      // Авторизация
      // =============================================================================
      {
        path: '/auth',
        redirect: '/sign-in',
        component: () => import('@/views/Authorization.vue'),
        children: [
          // =============================================================================
          // Логин
          // =============================================================================
          {
            path: '/sign-in',
            name: 'sign-in',
            meta: { rule: 'guest' },
            props: true,
            component: () => import('@/components/auth/SignIn.vue'),
          },
          // =============================================================================
          // Регистрация
          // =============================================================================
          {
            path: '/sign-up',
            name: 'sign-up',
            meta: { rule: 'guest' },
            component: () => import('@/components/auth/SignUp.vue'),
          },
          // =============================================================================
          // Восстановление пароля
          // =============================================================================
          {
            path: '/restore-password',
            name: 'restore-password',
            meta: { rule: 'guest' },
            component: () => import('@/components/auth/RestorePassword.vue'),
          },
        ],
      },
      // =============================================================================
      // Обработчики системных редиректов (UMI)
      // =============================================================================
      {
        path: '/users/restore/:code',
        name: 'users-restore',
        meta: { rule: 'guest' },
        component: () => import('@/views/system/UsersRestoreHandler.vue'),
      },
      // =============================================================================
      // Универсальная инфо/алерт страница
      // =============================================================================
      {
        path: '/info/:block',
        name: 'info-alert',
        meta: { rule: 'guest' },
        props: true,
        component: () => import('@/views/system/Info.vue'),
      },
      // =============================================================================
      // Страница успешной оплаты
      // =============================================================================
      {
        path: '/emarket/purchase/result/successful/',
        name: 'emarket-purchase-result-successful',
        meta: { rule: 'guest' },
        component: () => import('@/views/system/EmarketPurchaseSuccessful.vue'),
      },
      // =============================================================================
      // Страница НЕ успешной оплаты
      // =============================================================================
      {
        path: '/emarket/purchase/result/fail',
        name: 'emarket-purchase-result-fail',
        meta: { rule: 'guest' },
        component: () => import('@/views/system/EmarketPurchaseFail.vue'),
      },

      // =============================================================================
      // Страницы ошибок
      // =============================================================================
      {
        path: '/error-404',
        name: 'error-404',
        meta: { rule: 'guest' },
        component: () => import('@/views/system/Error404.vue'),
      },
      {
        path: '/error-403',
        name: 'error-403',
        meta: { rule: 'guest' },
        component: () => import('@/views/system/Error403.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: '/error-404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Проверяем авторизован ли пользователь и устанавливаем роль для прав доступа
  let user = await auth.getCurrentUser();
  if (user.is_auth) {
    store.commit('auth/SET_USER', user);
    router.app.$acl.change(user.user_role);
  } else {
    // Страницы доступные без авторизации
    if (to.meta.rule === 'guest') {
      return next();
    }
    return next({ name: 'sign-in' });
  }

  // Редиректы с корня и авторизационных страниц в зависимости от прав доступа
  if (
    to.path === '/' ||
    to.path === '/sign-in' ||
    to.path === '/sign-up' ||
    to.path === '/restore-password'
  ) {
    switch (true) {
      case router.app.$acl.check('notEmployee'):
        return next(store.state.user.root_route);
      case router.app.$acl.check('menuDashboard'):
        return next({ name: 'dashboard' });
      case router.app.$acl.check('manager'):
        return next({ name: 'manager-applications' });
      default:
        break;
    }
  }

  return next();
});

export default router;
